import { Link } from "@StarberryUtils";
import * as React from "react"
import {Button, Container, Row,Col } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
// images
import ImgPerson from "../../../images/person.jpg"
import cardImg01 from "../../../images/home/card-img01.webp"
import cardImg02 from "../../../images/home/card-img02.webp"
import cardImg03 from "../../../images/home/card-img03.webp"
import cardImg04 from "../../../images/home/card-img04.webp"
import cardImg05 from "../../../images/home/card-img05.webp"
import cardImg06 from "../../../images/home/card-img06.webp"
import cardImg07 from "../../../images/home/card-img07.webp"
import cardImg08 from "../../../images/home/card-img08.webp"
import ContactCard from "../ContactCard/ContactCard"
// Style
import  './ContactInfo.scss';
import ScrollAnimation from 'react-animate-on-scroll';

import {ImageModule} from "../../../modules/Image_Module";

const TilesBlock = ( props ) => {
    let path_name = typeof window !== "undefined" ?  (window.location.pathname).split('/') : '';

    //console.log("Tile_Blocks", props.Tile_Blocks)

    var tile_link = '';

return(
<>{props.Name && <div className="contact-info. cards-wrap">
  <Container>
    <Row className={"justify-content-center"}>
        {
            props.Tile_Blocks && props.Tile_Blocks.length > 0 && props.Tile_Blocks.map((item, index) => {

                if(item.URL){
                    if(item.URL.Main_Parent && item.URL.Sub_Parent && item.URL.Primary_URL){
                        tile_link = `/${item.URL.Main_Parent.Primary_URL}/${item.URL.Sub_Parent.Primary_URL}/${item.URL.Primary_URL}`;
                    } else if(item.URL && item.URL.Main_Parent && item.URL.Primary_URL){
                        tile_link = `/${item.URL.Main_Parent.Primary_URL}/${item.URL.Primary_URL}`;
                    } else{
                        tile_link = `/${item.URL.Primary_URL}`;
                    }
                }
                
                 
                return(
                    <Col md={6} lg={4} xl={3}>
                         <div className="animated">
                            {
                                props.Collection === "Community" ? (
                                    <Link to={item.URL}>
                                        <ContactCard 
                                            cardImg={item.Tile_Image}
                                            cardTitle={item.Name}
                                            imagename={"community-district.Tile_Image.tile_image"} 
                                            article_id={item.id} 
                                            imagetransforms={item.ggfx_results}
                                        />
                                    </Link>
                                ) : (                                    
                                    <Link to={item.Alternative_Link ? item.Alternative_Link : tile_link}>
                                        <ContactCard 
                                            cardImg={item.URL.Tile_Image}
                                            cardTitle={item.Title}
                                            imagename={"all-menu.Tile_Image.tile_image"} 
                                            article_id={item.URL.id} 
                                            imagetransforms={item.URL.ggfx_results}
                                        />
                                    </Link>
                                )
                            }
                            
                         </div>
                    </Col>
                )
            })
        }
        
    </Row>
    
  </Container>


</div>}</>
)

}
export default TilesBlock